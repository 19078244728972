import React, {useEffect, useState} from "react";
import Header from "../components/Header";
import Menu from "../components/Menu";
import {useHistory} from "react-router-dom";
import AppLink from "../components/Link";

const Home = () => {
    const history = useHistory();
    const [homeData, setHomeData] = useState([]);

    useEffect(() => {
        LoadHome();
    }, [])

    const LoadHome = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        formdata.append("page", "GetHomeDetails");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Doctor.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    console.log(Data);
                    setHomeData(Data);
                } else {
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }
    return (

        <section className="content">
            <div className="container-fluid">
                <div className="block-header">
                    <div className="row mb-0">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <ul className="breadcrumb breadcrumb-style ">
                                <li className="breadcrumb-item">
                                    <h4 className="page-title">Dashboard</h4>
                                </li>
                                <li className="breadcrumb-item bcrumb-1">
                                    <a href="index.html">
                                        <i className="fas fa-home"></i> Home</a>
                                </li>
                                <li className="breadcrumb-item active">Dashboard</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row ">
                        <div onClick={() => {
                            history.push('/doctors-list');
                        }} className="col-xl-3 col-sm-6 cursor-pointer">
                            <div className="card l-bg-purple">
                                <div className="info-box-5 p-4">
                                    <div className="card-icon card-icon-large"><i
                                        className="fas fa-users"></i></div>
                                    <div className="mb-4">
                                        <h5 className="font-20 mb-0">Total Doctors</h5>
                                    </div>
                                    <div className="row align-items-center mb-2 d-flex">
                                        <div className="col-8">
                                            <h3 className="text-white">
                                                {homeData.length ? homeData[0].Doctors : "0"}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 cursor-pointer" onClick={() => {
                            history.push('/customers-list');
                        }}>
                            <div className="card l-bg-blue-dark">
                                <div className="info-box-5 p-4">
                                    <div className="card-icon card-icon-large"><i className="fas fa-users"></i>
                                    </div>
                                    <div className="mb-4">
                                        <h5 className="font-20 mb-0">Customers</h5>
                                    </div>
                                    <div className="row align-items-center mb-2 d-flex">
                                        <div className="col-8">
                                            <h3 className="text-white">
                                                {homeData.length ? homeData[0].Customers : "0"}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 cursor-pointer" onClick={() => {
                            history.push('/appointments-list');
                        }}>
                            <div className="card l-bg-green-dark">
                                <div className="info-box-5 p-4">
                                    <div className="card-icon card-icon-large"><i className="fas fa-tasks"></i>
                                    </div>
                                    <div className="mb-4">
                                        <h5 className="font-20 mb-0">Appointments</h5>
                                    </div>
                                    <div className="row align-items-center mb-2 d-flex">
                                        <div className="col-8">
                                            <h3 className="text-white">
                                                {homeData.length ? homeData[0].Appointments : "0"}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6">
                            <div className="card l-bg-orange-dark">
                                <div className="info-box-5 p-4">
                                    <div className="card-icon card-icon-large"><i
                                        className="fas fa-money-check-alt"></i></div>
                                    <div className="mb-4">
                                        <h5 className="font-20 mb-0">Finance</h5>
                                    </div>
                                    <div className="row align-items-center mb-2 d-flex">
                                        <div className="col-8">
                                            <h3 className="text-white">
                                                ${homeData.length ? homeData[0].Earning : "0"}
                                            </h3>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
export default Home;
