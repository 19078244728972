import React, {useEffect, useRef, useState} from "react";
import {TabView, TabPanel} from 'primereact/tabview';
import {Toast} from "primereact/toast";
import {useHistory} from "react-router-dom";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import AppLink, {ImageLink} from "../components/Link";
import {ConfirmPopup, confirmPopup} from 'primereact/confirmpopup';
import $ from 'jquery';

export default function Configuration() {
    const navigate = useHistory();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [image, setImage] = useState('');
    const [branchData, setBranchData] = useState([]);
    const [sliderData, setSliderData] = useState([]);
    const [updateId, setUpdateId] = useState('');
    //Toaster Messages
    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    useEffect(() => {
        LoadSpecialization();
        LoadSliderDetails();
    }, [])

    async function LoadSpecialization() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadSpecialization");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Configuration.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setBranchData(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    async function LoadSliderDetails() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadSliders");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Configuration.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setSliderData(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const submitHandle = async (e) => {
        e.preventDefault();
        let img = $('input[name="image"]').prop('files')[0];
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formEl = document.forms.Form;
        var formdata = new FormData(formEl);
        if (img) {
            formdata.append("file", img);
        } else {
            formdata.append("file", new File([], ''));
        }
        if (updateId) {
            formdata.append("page", "UpdateSpecialization");
            formdata.append("id", updateId);
        } else {
            formdata.append("page", "SaveSpecialization");
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Configuration.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    LoadSpecialization();
                    showSuccess(updateId ? "Record Updated Successfully" : "Record Saved Successfully");
                    setName("");
                    setImage("");
                    $('input[name="image"]').val('');
                    setUpdateId("")

                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
                setLoading(false);
            })
            .catch(error => console.log('error', error));
    }
    const sliderHandle = async (e) => {
        e.preventDefault();
        let img = $('input[name="slider"]').prop('files')[0];
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        if (img) {
            formdata.append("slider", img);
        } else {
            formdata.append("slider", new File([], ''));
        }
        formdata.append("page", "SaveSlider");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Configuration.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {

                    LoadSliderDetails();
                    showSuccess("Record Saved Successfully");
                    $('input[name="slider"]').val('');
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
                setLoading(false);
            })
            .catch(error => console.log('error', error));
    }

    return (
        <section className="content">
            <Toast ref={toast}/>
            <ConfirmPopup/>
            <div className="card">
                <TabView>
                    <TabPanel header="Specialization Config.">
                        <form id={'Form'} onSubmit={submitHandle}>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>Name <code>*</code></label>
                                    <InputText value={name} onChange={(e) => setName(e.target.value)}
                                               name={'name'}
                                               required={true} placeholder={'Enter Name'}/>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Image <code>{image ? 'Image Uploaded' : '*'}</code></label>
                                        <InputText type={'file'}
                                                   onChange={(e) => setImage(e.target.value)}
                                                   name={'image'} className={'form-control'}
                                                   required={image ? false : true}/>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label>&nbsp;</label>
                                    {
                                        updateId ?
                                            <Button label={loading ? "Updating..." : "Update Record"} disabled={loading}
                                                    className={'form-control'} severity={'help'}
                                            />
                                            :
                                            <Button label={loading ? "Saving..." : "Save Record"} disabled={loading}
                                                    className={'form-control'}/>
                                    }
                                </div>
                            </div>
                        </form>
                        <div className="row mt-4">
                            <DataTable value={branchData} loading={loading} emptyMessage={'No Record Found !'}
                                       paginator
                                       rows={10}
                                       rowsPerPageOptions={[5, 10, 25, 50]}
                                       tableStyle={{minWidth: '50rem', overflow: "auto"}}
                            >
                                <Column field="Name" header="Name" style={{minWidth: '10rem'}}/>
                                <Column header="Image" style={{minWidth: '10rem'}} body={(p) => {
                                    return (
                                        <img src={ImageLink + 'Specialization/' + p.Image}/>
                                    )
                                }}/>
                                <Column header="Delete" style={{maxWidth: '5rem'}} body={(p) => {
                                    const acceptDelete = async () => {
                                        var myHeaders = new Headers();
                                        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
                                        var formdata = new FormData();
                                        formdata.append("page", "DeleteSpecialization");
                                        formdata.append("id", p.id);
                                        var requestOptions = {
                                            method: 'POST',
                                            headers: myHeaders,
                                            body: formdata,
                                            redirect: 'follow'
                                        };

                                        await fetch(`${AppLink}Configuration.php`, requestOptions)
                                            .then(response => response.json())
                                            .then(result => {
                                                console.log(result);
                                                setLoading(false);
                                                if (result.status == "1") {
                                                    // let Data = result.Data;
                                                    LoadSpecialization();
                                                    showSuccess("Record Deleted Successfully");
                                                } else {
                                                    showError();
                                                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                                                }
                                            })
                                            .catch(error => console.log('error', error));
                                    }
                                    return (
                                        <Button icon={'pi pi-trash'} severity={'danger'} onClick={async (event) => {
                                            confirmPopup({
                                                target: event.currentTarget,
                                                message: 'Do you want to delete this record?',
                                                icon: 'pi pi-info-circle',
                                                acceptClassName: 'p-button-danger',
                                                accept() {
                                                    acceptDelete()
                                                },
                                                reject() {
                                                    console.log("Reject");
                                                }
                                            });


                                        }}/>
                                    )
                                }}/>
                                <Column header={'Edit'} style={{maxWidth: '5rem'}} body={(p) => {
                                    return (
                                        <Button icon={'pi pi-pencil'} severity={'info'} onClick={() => {
                                            setUpdateId(p.id);
                                            setName(p.Name)
                                            setImage(p.Image)
                                        }}/>
                                    )
                                }}/>
                            </DataTable>
                        </div>
                    </TabPanel>
                    <TabPanel header="Customer Home Slider">
                        <form id={'SliderForm'} onSubmit={sliderHandle}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Image <code>*</code></label>
                                        <InputText type={'file'}
                                                   name={'slider'} className={'form-control'}
                                                   required={true}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label>&nbsp;</label>
                                    {
                                        updateId ?
                                            <Button label={loading ? "Processing..." : "Update Record"}
                                                    disabled={loading}
                                                    className={'form-control'} severity={'help'}
                                            />
                                            :
                                            <Button label={loading ? "Processing..." : "Save Record"} disabled={loading}
                                                    className={'form-control'}/>
                                    }
                                </div>
                            </div>
                        </form>
                        <div className="row mt-4">
                            <DataTable value={sliderData} loading={loading} emptyMessage={'No Record Found !'}
                                       paginator
                                       rows={10}
                                       rowsPerPageOptions={[5, 10, 25, 50]}
                                       tableStyle={{minWidth: '50rem', overflow: "auto"}}
                            >
                                <Column header="Image" style={{minWidth: '10rem'}} body={(p) => {
                                    return (
                                        <img src={ImageLink + 'Slider/' + p.Image}
                                             style={{width: "auto", height: 150, objectFit: "fill"}}/>
                                    )
                                }}/>
                                <Column header="Delete" style={{maxWidth: '5rem'}} body={(p) => {
                                    const acceptDelete = async () => {
                                        var myHeaders = new Headers();
                                        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
                                        var formdata = new FormData();
                                        formdata.append("page", "DeleteSlider");
                                        formdata.append("id", p.id);
                                        var requestOptions = {
                                            method: 'POST',
                                            headers: myHeaders,
                                            body: formdata,
                                            redirect: 'follow'
                                        };

                                        await fetch(`${AppLink}Configuration.php`, requestOptions)
                                            .then(response => response.json())
                                            .then(result => {
                                                console.log(result);
                                                setLoading(false);
                                                if (result.status == "1") {
                                                    // let Data = result.Data;
                                                    LoadSliderDetails();
                                                    showSuccess("Record Deleted Successfully");
                                                } else {
                                                    showError();
                                                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                                                }
                                            })
                                            .catch(error => console.log('error', error));
                                    }
                                    return (
                                        <Button icon={'pi pi-trash'} severity={'danger'} onClick={async (event) => {
                                            confirmPopup({
                                                target: event.currentTarget,
                                                message: 'Do you want to delete this record?',
                                                icon: 'pi pi-info-circle',
                                                acceptClassName: 'p-button-danger',
                                                accept() {
                                                    acceptDelete()
                                                },
                                                reject() {
                                                    console.log("Reject");
                                                }
                                            });


                                        }}/>
                                    )
                                }}/>
                            </DataTable>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </section>
    )
}