import React, {useState, useEffect, useRef} from 'react';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {Card} from 'primereact/card';
import {Toast} from "primereact/toast";
import $ from 'jquery';
import AppLink from "../components/Link";
import {useHistory} from "react-router-dom";

export default function AddSubscription() {
    const navigate = useHistory();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [leadData, setLeadData] = useState([]);
    const [price, setPrice] = useState('');
    const [duration, setDuration] = useState('');
    const [discount, setDiscount] = useState('');
    const [count, setCount] = useState('');
    const [type, setType] = useState('');

    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const submitHandle = async (e) => {
        e.preventDefault();
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formEl = document.forms.Form;
        var formdata = new FormData(formEl);
        formdata.append("page", "AddSubscription");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}users.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setLoading(false);
                if (result.status == "1") {
                    $('#Form').trigger('reset');
                    setPrice("")
                    setDiscount("")
                    setDuration("")
                    setCount("")
                    setType("")
                    showSuccess(leadData.length > 0 ? "Record Updated Successfully" : "Record Saved Successfully");
                    setTimeout(() => {
                        navigate.go(-1)
                    }, 1000)
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <section className="content">
            <Card
                title={() => {
                    return (
                        <>
                            <button onClick={() => navigate.go(-1)}
                                    style={{border: "none", outline: "none", background: "transparent"}}><i
                                className={'fas fa-arrow-left'}></i></button>
                            &nbsp;&nbsp;&nbsp;
                            <span>Add Subscription</span>
                        </>
                    )
                }}
                className="card">
                <Toast ref={toast}/>
                <form id={'Form'} onSubmit={submitHandle}>
                    <div className="row">
                        <div className="col-md-6">
                            <label>Price <code>*</code></label>
                            <InputText value={price} onChange={(e) => setPrice(e.target.value)} name='price'
                                       placeholder={'Enter Price'} type={"number"} autoFocus={true} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Discount <code>*</code></label>
                            <InputText value={discount} onChange={(e) => setDiscount(e.target.value)} name='discount'
                                       placeholder={'Enter Discount %'} type={"number"} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Duration <code>*</code></label>
                            <InputText value={duration} onChange={(e) => setDuration(e.target.value)}
                                       name='duration'
                                       placeholder={'e.g. 1 Month/6 Month'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Consultation For <code>*</code></label>
                            <InputText value={type} onChange={(e) => setType(e.target.value)}
                                       name='type'
                                       placeholder={'e.g. General Physicians/Mental Expert'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Consultation Count <code>*</code></label>
                            <InputText value={count} onChange={(e) => setCount(e.target.value)}
                                       name='count'
                                       placeholder={'e.g. General Physicians/Mental Expert'} required={true}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        {
                            leadData.length > 0 ?
                                <Button label={loading ? "Updating..." : "Update Record"} severity={'help'}
                                        disabled={loading}/> :
                                <Button label={loading ? "Saving..." : "Save Record"} disabled={loading}/>
                        }
                        {/*<Button  label={loading ? "Saving..." : "Save Record"} disabled={loading} />*/}
                    </div>
                </form>
            </Card>
        </section>

    );
}
