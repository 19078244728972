import './App.css';
import React from "react";
import Header from "./components/Header";
import Menu from "./components/Menu";
import {Route, Switch} from "react-router-dom";
import Home from "./screens/Home";
import LeadsList from "./screens/LeadsList";
import AddLeads from "./screens/AddLeads";
import NotFound from "./screens/NotFound";
import Login from "./screens/Login";
import UsersList from "./screens/UsersList";
import AddUser from "./screens/AddUser";
import Configuration from "./screens/Configuration";
import DoctorsList from "./screens/DoctorsList";
import CustomersList from "./screens/CustomersList";
import AppointmentsList from "./screens/AppointmentsList";
import CustomerReviews from "./screens/CustomerReviews";
import AddDoctor from "./screens/AddDoctor";
import AddSubscription from "./screens/AddSubscription";
import SubscriptionList from "./screens/SubscriptionList";
import Payments from "./screens/Payments";

function App() {
    return (
        <>
            {/*<div className="loader"></div>*/}
            <Switch>
                <Route exact path='/login' component={Login}/>

                <div id="app">
                    <div className="main-wrapper main-wrapper-1">
                        <Header/>
                        <Menu/>
                        <Route exact path='/' component={Home}/>
                        <Route exact path='/users' component={UsersList}/>
                        <Route exact path='/add-user' component={AddUser}/>
                        <Route exact path='/leads-list' component={LeadsList}/>
                        <Route exact path='/add-leads' component={AddLeads}/>
                        <Route exact path='/configuration' component={Configuration}/>
                        <Route exact path='/doctors-list' component={DoctorsList}/>
                        <Route exact path='/customers-list' component={CustomersList}/>
                        <Route exact path='/appointments-list' component={AppointmentsList}/>
                        <Route exact path='/reviews-list' component={CustomerReviews}/>
                        <Route exact path='/add-doctor' component={AddDoctor}/>
                        <Route exact path='/add-subscription' component={AddSubscription}/>
                        <Route exact path='/subscriptions' component={SubscriptionList}/>
                        <Route exact path='/payments' component={Payments}/>
                    </div>
                </div>
                <Route component={NotFound}/>
            </Switch>
        </>
    );
}

export default App;
