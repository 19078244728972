import React from "react";
import {NavLink, useHistory} from 'react-router-dom';

const Menu = () => {
    const history = useHistory();
    return (
        <>
            <div>
                <aside id="leftsidebar" className="sidebar">
                    <div className="menu">
                        <ul className="list">
                            <li className="sidebar-user-panel active">
                                <div className="user-panel">
                                    <div className=" image">
                                        <img src="assets/images/user.png" className="user-img-style"
                                             alt="User Image"/>
                                    </div>
                                </div>
                                <div className="profile-usertitle">
                                    <div className="sidebar-userpic-name"> {localStorage.getItem("UserName")}</div>
                                    <div className="profile-usertitle-job ">{localStorage.getItem("Role")}</div>
                                </div>
                            </li>
                            <li className="header">-- Pages</li>
                            <li>
                                <NavLink to={'/'} exact={true}>
                                    <i data-feather="monitor"></i>
                                    <span>Home</span>
                                </NavLink>
                            </li>

                            {/*<li className={localStorage.getItem("Role") === "Operator" || localStorage.getItem("Role") === "Counselor" ? "hide" : ""}>*/}
                            {/*    <NavLink to={'/users'} exact={true}>*/}
                            {/*        <i data-feather="users"></i>*/}
                            {/*        <span>Users Config.</span>*/}
                            {/*    </NavLink>*/}
                            {/*</li>*/}
                            <li>
                                <NavLink to={'/users'} exact={true}>
                                    <i data-feather="users"></i>
                                    <span>Users Config.</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'/configuration'} exact={true}>
                                    <i data-feather="settings"></i>
                                    <span>Gen. Configuration</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'/subscriptions'} exact={true}>
                                    <i data-feather="database"></i>
                                    <span>Manage Subscription</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'/doctors-list'} exact={true}>
                                    <i data-feather="user-check"></i>
                                    <span>Doctors List</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'/customers-list'} exact={true}>
                                    <i data-feather="users"></i>
                                    <span>Patients Data</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'/appointments-list'} exact={true}>
                                    <i data-feather="calendar"></i>
                                    <span>Appointments List</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'/payments'} exact={true}>
                                    <i data-feather="dollar-sign"></i>
                                    <span>Payments</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'/reviews-list'} exact={true}>
                                    <i data-feather="activity"></i>
                                    <span>Customer Reviews</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'/#'} exact={true}>
                                    <i data-feather="thermometer"></i>
                                    <span>Manage Laboratories</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'/#'} exact={true}>
                                    <i data-feather="thermometer"></i>
                                    <span>Add Pharmacy</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'/#'} exact={true}>
                                    <i data-feather="thermometer"></i>
                                    <span>Manage Pharmacy</span>
                                </NavLink>
                            </li>
                            {/*<li>*/}
                            {/*    <a href="#" class="menu-toggle">*/}
                            {/*        <i data-feather="users"></i>*/}
                            {/*        <span>Consultancy</span>*/}
                            {/*    </a>*/}
                            {/*    <ul class="ml-menu">*/}
                            {/*        <li className={localStorage.getItem("Role") === "Counselor" ? "hide" : ""}>*/}
                            {/*            <NavLink to={'/leads-list'} exact={true}>*/}
                            {/*                <i data-feather="settings"></i>*/}
                            {/*                <span>Leads</span>*/}
                            {/*            </NavLink>*/}
                            {/*        </li>*/}
                            {/*        <li className={localStorage.getItem("Role") === "Counselor" ? "hide" : ""}>*/}
                            {/*            <NavLink to={'/follow-up'}>*/}
                            {/*                <i data-feather="book"></i>*/}
                            {/*                <span>Follow Up</span>*/}
                            {/*            </NavLink>*/}
                            {/*        </li>*/}
                            {/*        <li className={localStorage.getItem("Role") === "Operator" ? "hide" : ""}>*/}
                            {/*            <NavLink to={'/applications'}>*/}
                            {/*                <i data-feather="award"></i>*/}
                            {/*                <span>Applications</span>*/}
                            {/*            </NavLink>*/}
                            {/*        </li>*/}
                            {/*        <li className={localStorage.getItem("Role") === "Operator" ? "hide" : ""}>*/}
                            {/*            <NavLink to={'/visa'}>*/}
                            {/*                <i data-feather="tv"></i>*/}
                            {/*                <span>Visa</span>*/}
                            {/*            </NavLink>*/}
                            {/*        </li>*/}
                            {/*        <li className={localStorage.getItem("Role") === "Operator" ? "hide" : ""}>*/}
                            {/*            <NavLink to={'/gte'}>*/}
                            {/*                <i data-feather="file"></i>*/}
                            {/*                <span>GTE</span>*/}
                            {/*            </NavLink>*/}
                            {/*        </li>*/}
                            {/*        <li className={localStorage.getItem("Role") === "Operator" || localStorage.getItem("Role") === "Counselor" ? "hide" : ""}>*/}
                            {/*            <NavLink to={'/status'}>*/}
                            {/*                <i data-feather="user"></i>*/}
                            {/*                <span>Status</span>*/}
                            {/*            </NavLink>*/}
                            {/*        </li>*/}
                            {/*    </ul>*/}
                            {/*</li>*/}
                        </ul>
                    </div>
                </aside>
            </div>
        </>
    )
}
export default Menu;
