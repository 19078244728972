import React, {useState, useEffect, useRef} from 'react';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {Card} from 'primereact/card';
import {Toast} from "primereact/toast";
import $ from 'jquery';
import AppLink from "../components/Link";
import {useHistory} from "react-router-dom";

export default function AddDoctor() {
    const navigate = useHistory();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [password, setPassword] = useState('');
    const [specialization, setSpecialization] = useState('');
    const [experience, setExperience] = useState('');
    const [specializationData, setSpecializationData] = useState([]);
    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    useEffect(() => {
        LoadSpecialization();
    }, [])

    async function LoadSpecialization() {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadSpecialization");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Configuration.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setSpecializationData(Data);
                } else {
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const submitHandle = async (e) => {
        e.preventDefault();
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formEl = document.forms.Form;
        var formdata = new FormData(formEl);
        formdata.append("page", "SaveDoctor");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}users.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setLoading(false);
                if (result.status == "1") {
                    $('#Form').trigger('reset');
                    setName("");
                    setEmail("");
                    setPassword("");
                    showSuccess("Record Saved Successfully");
                    // setCustomerData(Data);
                    setTimeout(() => {
                        navigate.go(-1)
                    }, 1000)
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <section className="content">
            <Card
                title={() => {
                    return (
                        <>
                            <button onClick={() => navigate.go(-1)}
                                    style={{border: "none", outline: "none", background: "transparent"}}><i
                                className={'fas fa-arrow-left'}></i></button>
                            &nbsp;&nbsp;&nbsp;
                            <span>Add Doctor</span>
                        </>
                    )
                }}
                className="card">
                <Toast ref={toast}/>
                <form id={'Form'} onSubmit={submitHandle}>
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="Name">Name <code>*</code></label>
                            <InputText id="Name" value={name} onChange={(e) => setName(e.target.value)} name='name'
                                       placeholder={'Enter Name'} autoFocus={true} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Email Address <code>*</code></label>
                            <InputText id="Email" value={email} onChange={(e) => setEmail(e.target.value)} name='email'
                                       placeholder={'Enter Email Address'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Contact No <code>*</code></label>
                            <InputText value={contact} onChange={(e) => setContact(e.target.value)} name='contact'
                                       placeholder={'Enter ContactNo'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Country <code>*</code></label>
                            <InputText value={country} onChange={(e) => setCountry(e.target.value)} name='country'
                                       placeholder={'Enter Country'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>State <code>*</code></label>
                            <InputText value={state} onChange={(e) => setState(e.target.value)} name='state'
                                       placeholder={'Enter State'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>City <code>*</code></label>
                            <InputText value={city} onChange={(e) => setCity(e.target.value)} name='city'
                                       placeholder={'Enter City'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Address <code>*</code></label>
                            <InputText value={address} onChange={(e) => setAddress(e.target.value)} name='address'
                                       placeholder={'Enter Address'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Specialization <code>*</code></label>
                                <select name="specialization" id="specialization" className={'form-control'}>
                                    {
                                        specializationData.map((s) => {
                                            return (
                                                <option value={s.Name}>{s.Name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label>Experience <code>*</code></label>
                            <InputText value={experience} onChange={(e) => setExperience(e.target.value)}
                                       name='experience'
                                       placeholder={'Enter Experience'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Password <code>*</code></label>
                            <InputText id="Password" value={password} onChange={(e) => setPassword(e.target.value)}
                                       name='password'
                                       placeholder={'Enter Password'} type={'password'} required={true}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <Button label={loading ? "Saving..." : "Save Record"} disabled={loading}/>
                    </div>
                </form>
            </Card>
        </section>

    );
}
