import React, {useState, useEffect, useRef} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {useHistory} from "react-router-dom";
import AppLink, {ImageLink} from "../components/Link";
import {Toast} from "primereact/toast";
import {ConfirmPopup, confirmPopup} from 'primereact/confirmpopup';

export default function AppointmentsList() {
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const [doctorData, setDoctorData] = useState([]);
    const [filterRecord, setFilterRecord] = useState([]);
    useEffect(() => {
        LoadData();
    }, [])

    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const LoadData = async () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadAppointmentsForAdmin");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Doctor.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setDoctorData(Data);
                    setFilterRecord(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }
    const FilterRecord = async (date) => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadAppointmentsWithDate");
        formdata.append("date", date);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Doctor.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setDoctorData(Data);
                    setFilterRecord(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const filterWiseData = (text) => {
        if (text != "reset") {
            const newData = filterRecord.filter(item => {
                return item.Status == text;
            })
            setDoctorData(newData);
        } else {
            setDoctorData(filterRecord);
        }
    }

    return (
        <section className="content">
            <Card
                header={() => {
                    return (
                        <div className="row justify-content-between mt-4">
                            <div className="col-md-5 m-l-5">
                                <button className={'btn btn-primary'} onClick={()=> filterWiseData("Approved")}>Approved</button>
                                <button className={'btn btn-success m-l-5'} onClick={()=> filterWiseData("Completed")}>Completed</button>
                                <button className={'btn btn-warning m-l-5'} onClick={()=> filterWiseData("Cancel")}>Cancelled</button>
                                <button className={'btn btn-danger m-l-5'} onClick={()=> filterWiseData("reset")}>Reset</button>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="">Filter Record *</label>
                                <input type="date" className={'form-control'} onChange={(e) => {
                                    let date = new Date(e.target.value);
                                    date = date.toLocaleDateString('en-US');
                                    FilterRecord(date);
                                }}/>
                            </div>
                        </div>
                    )
                }
                }
                title={"Appointments List"} className="card">
                <Toast ref={toast}/>
                <ConfirmPopup/>
                <DataTable value={doctorData} loading={loading} emptyMessage={'No Record Found !'} paginator
                           rows={10}
                           rowsPerPageOptions={[5, 10, 25, 50]}
                           tableStyle={{minWidth: '50rem', overflow: "auto"}}>
                    <Column field="Name" header="Doctor Name" style={{minWidth: '12rem'}}/>
                    <Column field="Email" header="Email" style={{minWidth: '12rem'}}/>
                    <Column field="ContactNo" header="Contact No" style={{minWidth: '12rem'}}/>
                    <Column field="CustomerName" header="Customer Name" style={{minWidth: '12rem'}}/>
                    <Column field="CustomerContact" header="Customer Contact" style={{minWidth: '12rem'}}/>
                    <Column field="Date" header="Appointment Date" style={{minWidth: '12rem'}}/>
                    <Column header="Time Slot" style={{minWidth: '12rem'}} body={(p) => {
                        if (p.Form)
                            return (
                                <p>{p.From} to {p.To}</p>
                            )
                        else
                            return (
                                <p>Urgent</p>
                            )
                    }}/>
                    <Column field="Amount" header="Amount" style={{minWidth: '12rem'}}/>
                    <Column field="Status" header="Status" style={{minWidth: '12rem'}}/>
                </DataTable>
            </Card>
        </section>
    );
}
